<template>
  <div>
	  <el-input v-model="val"/>
    <el-button @click="test()">加密</el-button>
	  <el-button @click="test1()">车辆定位</el-button>
    <div>


    </div>
  </div>
</template>
<script>
import {Encrypt, Decrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";
import {mapGetters, mapMutations} from "vuex";
import router from "@/router";

export default {
	data(){
		return{
			val: ''
		}
	},
	computed: {
		...mapGetters(['GET_USERID'])
	},
  methods: {
	  ...mapMutations(['SET_TOKEN_CAR']),
    test() {
      console.log(Encrypt(this.val))
			console.log(Decrypt(Encrypt(this.val)))
	    console.log(Decrypt(this.val))
    },
	  test1(){
			this.axios.post('/userinfo/getTokenCra', (response) => {
				this.SET_TOKEN_CAR = response.obj

			},{
				user_id: this.GET_USERID
			})
	  }
  },
	mounted() {
		var socket;
		if (typeof (WebSocket) == "undefined") {
			console.log("遗憾：您的浏览器不支持WebSocket");
		} else {
			console.log("恭喜：您的浏览器支持WebSocket");
			socket = new WebSocket("ws://localhost:8086/webSocket/abc");
			socket.onopen = function() {
				socket.send("消息发送测试(From Client)");
			};
			//收到消息事件
			socket.onmessage = function(msg) {
				console.log(msg)
			};
			socket.onclose = function() {
				//console.log("Socket已关闭");
			};
			//发生了错误事件
			socket.onerror = function() {
				//alert("Socket发生了错误");
			}

			//窗口关闭时，关闭连接
			window.unload=function() {
				socket.close();
			};
		}
	}
}
</script>
<style>


</style>
